<template>
  <div style="margin-right: 10px; margin-left: 10px">
    <div
      class="panel-body row merchpromlist"
      style="margin-bottom: 10px; padding-top: 0px; margin-top: -5px"
    >
      <div class="col-md-12" style="margin-bottom: 0px" v-if="showFlash">
        <div class="well row" style="margin-bottom: 10px">

         
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 flashdeal-time--boxes-wpr" style="text-align: center">
              <div class="row" style="text-align: center;">
                <div style="margin: auto">
                  <!-- Mobile ends in text starts -->
                  <div class="mobile-endsiin-text">
                    <div class="counternumbers">Flash Deal Ends in</div>
                  </div>
                  <!-- Mobile ends in text ends -->
                  <!-- Desktop ends in text and seperator starts -->
                  <div style="float: left" class="desktop-endsiin-text">
                    <div class="Rectangle-841 merchprmolist--fdeal-coutbox">
                      <div class="counternumbers">Flash Deal Ends in</div>
                    </div>
                    <!-- <p style="margin-top: 5px"><b>Days</b></p> -->
                  </div>
                  <div class="countbox--seperator desktop-endsiin-seperator"
                    style="
                      float: left;
                      padding: 4px;
                    "
                  >
                    :
                  </div>
                  <!-- Desktop ends in text and seperator ends -->

                  <div style="float: left">
                    <div class="Rectangle-841 merchprmolist--fdeal-coutbox">
                      <div class="counternumbers">{{ dealHours }}</div>
                    </div>
                    <p style="margin-top: 5px"><b>Hours</b></p>
                  </div>
                  <div class="countbox--seperator"
                    style="
                      float: left;
                      padding: 4px;
                    "
                  >
                    :
                  </div>
                  <div style="float: left">
                    <div class="Rectangle-841 merchprmolist--fdeal-coutbox">
                      <div class="counternumbers">{{ dealMinutes }}</div>
                    </div>
                    <p style="margin-top: 5px"><b>Minutes</b></p>
                  </div>
                  <div class="countbox--seperator"
                    style="
                      float: left;
                      padding: 4px;
                    "
                  >
                    :
                  </div>
                  <div style="float: left">
                    <div class="Rectangle-841 merchprmolist--fdeal-coutbox">
                      <div class="counternumbers">{{ dealSeconds }}</div>
                    </div>
                    <p style="margin-top: 5px"><b>Seconds</b></p>
                  </div>
                </div>
              </div>
            </div>


          <div class="col-lg-12 col-md-12 col-sm-12 merchpromo-list--left" style="padding-bottom: 15px">
            

            <!-- <div class="col-md-12" style="border-top: solid 1px #eeeeee"> -->
            <div class="col-md-12">
              <h4 style="text-align: center; padding: 5px">
                Cochchi Flash Sales
              </h4>
            </div>

            <div class="col-md-6" style="padding-left: 0px; padding-right: 0px">
              <p style="text-align: left; padding: 5px; padding-bottom: 0px">
                Date : {{ dealdates }}
              </p>
            </div>
            <div class="col-md-6" style="padding-left: 0px; padding-right: 0px">
              <div class="Rectangle-845">
                <p class="mindiscount">{{ this.dealMindiscount }}%</p>
              </div>
              <p
                style="
                  text-align: right;
                  padding: 5px;
                  padding-bottom: 0px;
                  float: right;
                "
              >
                Minimum Discount :
              </p>
            </div>

            <div
              class="col-md-12"
              style="padding-left: 0px; padding-right: 0px"
            >
              <p style="text-align: left; padding: 5px; padding-bottom: 0px">
                Register before : {{ this.dealEnddate }}
              </p>
            </div>

            <div class="col-md-4" style="padding-left: 0px; padding-right: 0px">
              <p style="text-align: left; padding: 5px; padding-bottom: 0px">
                Sellers count : {{ dealSellercount }}
              </p>
            </div>
            <div class="col-md-8" style="padding-left: 0px; padding-right: 0px">
              <p style="text-align: left; padding: 5px; padding-bottom: 0px">
                Product count : {{ dealProductcount }}
              </p>
            </div>

            <div
              class="col-md-12"
              style="padding-left: 0px; padding-right: 0px; text-align: center"
            >
              <button
                class="btn merchprof-sbtn"
                style="width: 200px"
                @click="haddleJoinNow(newFDId)"
                title="You can add product in this promotion"
              >
                Join Now
              </button>
            </div>
          </div>

          <!-- BANNER RIGHT -->
          <div class="col-lg-6 col-md-12 col-sm-12 merchpromo-list--right">
            <img class="img-responsive" :src="dealimage" />
          </div>
        </div>
      </div>

      <div class="row" style="padding-bottom: 10px">
          <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12" style="">
            <h4>FLASH DEALS</h4>
          </div>

          <div
            class="col-lg-9 col-md-12 col-sm-12 text-right merchpromolist--flasd-filter-from-to"
            style="
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: stretch;
            "
          >
            <div style="margin-top: 10px" class="merchpromolist--flasd-filter-from-to-left">
              From
              <datepicker
                style="display: inline-block; margin-right: 10px"
                v-model="filterDatefrom"
                placeholder="Select Date"
                :disabledDates="disabledFromDates"
                @selected="setEmtyToDate()"
              ></datepicker>
            </div>
            <div style="margin-top: 10px" class="merchpromolist--flasd-filter-from-to-right">
              To
              <datepicker
                style="
                  display: inline-block;
                  margin-right: 10px;
                  margin-left: 10px;
                "
                calendar-class="my-datepicker_calendar"
                v-model="filterDateto"
                placeholder="Select Date"
                :disabledDates="disabledToDates"
              ></datepicker>
            </div>
            <div style="margin-top: 10px">
              <button
                @click="searchOrders()"
                style="vertical-align: inherit; margin-right: 0px"
                class="btn merchprof-sbtn"
              >
                Filter
              </button>
            </div>
          </div>
      </div>
      <!-- <div class="col-sm-3" style="padding-right:0px;">
        <button type="button" class="btn btn-primary" style="float: right;" @click="handleCreate">
          Add New
        </button>
      </div> -->
      <div style="overflow-x: scroll">
        <table id="example" class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="head-table">Start Date</th>
              <th scope="col" class="head-table">Start Time</th>
              <th scope="col" class="head-table">End Date</th>
              <th scope="col" class="head-table">End Time</th>
              <th scope="col" class="head-table">Min Discount</th>
              <th scope="col" class="head-table">Min Price</th>
              <th scope="col" class="head-table">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(column, index) in tableItems" :key="index">
              <td>{{ column.start_date }}</td>
              <td>{{ column.start_time }}</td>
              <td>{{ column.end_date }}</td>
              <td>{{ column.end_time }}</td>
              <td>{{ column.min_discount }}</td>
              <td>{{ column.min_price }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  :disabled="column.isAssign === true"
                  style="background-color: #005baa"
                  :style="column.isAssign === true ? 'background-color: gray;' : 'background-color: #005baa'"
                  @click="handleAssignProduct(column._id)"
                >
                  Assign
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row text-right">
        <div class="col-sm-12">
          <div class="mt-3">
            <b-pagination
              v-model="currentPage"
              :per-page="pageSize"
              :total-rows="rowcount"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import mixinFlashDeal from "@/mixins/merchant/APIFlashDeal";
export default {
  mixins: [mixinFlashDeal],
  components: {
    Datepicker,
  },
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Promotion List",
          href: "/merchant-promotions-list",
          active: true,
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      showFlash: false,
      dealimage: "",
      dealdates: "",
      dealMindiscount: 0,
      dealEnddate: "",
      dealDays: 0,
      dealHours: 0,
      dealMinutes: 0,
      dealSeconds: 0,
      dealSellercount: 0,
      dealProductcount: 0,
      filterDatefrom: new Date(),
      filterDateto: new Date(),
      disabledFromDates: {
        from: new Date()
      },
      disabledToDates: {
        from: new Date()
      },
      newFDId: "",
    };
  },
  watch: {
    searchKey: function (val) {
      this.searchCustomers(val);
    },
    currentPage: function (val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function () {
      this.searchCustomers(this.searchKey);
    },
    filterDatefrom: function (val) {
      this.disabledToDates = {
        from: new Date(),
        to:new Date(val)
      }

    }
  },
  created() {
    this.handleBreadcrumb();
    this.handleGetStores();
    this.filterDatefrom.setDate(this.filterDatefrom.getDate() - 14);
    this.handleGetLivePromotions();
  },
  methods: {
    haddleJoinNow: function (itemId) {
      this.$router.push({
        name: "Promotion Configure To Product",
        params: { id: itemId },
      });
    },
    setEmtyToDate: function () {
      this.filterDateto = "";
    },
    handleBreadcrumb: function () {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    handleAssignProduct: function (itemId) {
      this.$router.push({
        name: "Promotion Configure To Product",
        params: { id: itemId },
      });
    },
    handleGetLivePromotions: async function () {
      try {
        let response = await this.getLivePromotions();
        if (response.now.active_status) {
          this.showFlash = true;
          this.newFDId = response.now._id;
          this.dealimage = response.now.image;
          var daterange =
            this.formatDate(new Date(response.now.start_date_time)) +
            " To " +
            this.formatDate(new Date(response.now.end_date_time));
          this.dealdates = daterange;
          this.dealEnddate = this.formatDate(
            new Date(response.now.end_date_time)
          );
          this.dealMindiscount = response.now.min_discount;
          this.dealSellercount = response.merchant_count;
          this.dealProductcount = response.product_count;
          this.dealTime(response.time_now, response.now.end_date_time);
        } else {
          this.showFlash = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      return day + " " + monthNames[monthIndex] + " " + year;
    },
    dealTime(startdate, dateend) {
      var startDate = new Date(startdate);
      // Do your operations
      var endDate = new Date(dateend);
      var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      clearInterval(promolistdealtimer);
      if (seconds <= 1) {
        this.dealHours = 0;
        this.dealMinutes = "0.0";
        this.dealSeconds = 0;
        this.dealDays = 0;
        this.showFlash = false;

        return;
      }
      let self = this;
      var promolistdealtimer = setInterval(function () {
        seconds = seconds - 1;
        if (self.$route.name != "Promotions List") {
          clearInterval(promolistdealtimer);
        }

        if (seconds <= 0) {
          clearInterval(promolistdealtimer);
          return;
        }

        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(seconds / 3600);
        var textseconds = seconds - minutes * 60;
        var textminutes = String((seconds % 3600) / 60);

        var days = Math.floor(seconds / 3600 / 24);

        self.dealDays = parseFloat(days).toFixed(0);
        self.dealHours = parseFloat(hours).toFixed(0);
        self.dealMinutes = Math.floor(parseInt("" + textminutes * 100) / 100);
        self.dealSeconds = parseFloat(textseconds).toFixed(0);
      }, 1000);
    },
    handleGetStores: async function () {
      try {
        let response = await this.getFlashDealList();
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          let endDate = element.end_date;
          this.enableAssinBtn(endDate);
          let res = this.enableAssinBtn(endDate);
          if (res) {
            element.isAssign = true;
          } else {
            element.isAssign = false;
          }
        }
        this.storeListData = response;
        this.searchOrders("");
      } catch (error) {
        console.log(error);
      }
    },

    enableAssinBtn(end) {
      var tDate, eDate;
      tDate = Date.parse(new Date().toLocaleDateString());
      eDate = Date.parse(new Date(end));
      if (tDate > eDate) {
        return true;
      }
      return false;
    },
    searchOrders: function (keyword) {
      let pagesize = parseInt(this.pageSize);
      for (let index = 0; index < this.storeListData.length; index++) {
        const element = this.storeListData[index];
        let endDate = element.end_date;
        this.enableAssinBtn(endDate);
        let res = this.enableAssinBtn(endDate);
        if (res) {
          element.isAssign = true;
        } else {
          element.isAssign = false;
        }
      }

      this.tableItems = this.storeListData
        .filter((obj) => {
          {
            var dateFrom = new Date(this.filterDatefrom).toLocaleDateString();
            var dateTo = new Date(this.filterDateto).toLocaleDateString();
            var dateCheck = new Date(obj.end_date_time).toLocaleDateString();

            var res = this.dateCheck(dateFrom, dateTo, dateCheck);
            if (res) {
              return obj;
            }
          }
        })
        .slice(0, pagesize);

      this.rowcount = this.storeListData.length;
    },
    dateCheck(from, to, check) {
      var fDate, lDate, cDate;
      fDate = Date.parse(from);
      lDate = Date.parse(to);
      cDate = Date.parse(check);

      if (cDate <= lDate && cDate >= fDate) {
        return true;
      }
      return false;
    },
    pagination: function (pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);

      let endsize = pagesize * currentPage;
      let startsize = 0;

      if (currentPage === 1) {
        startsize = 0;
      } else {
        startsize = endsize - pagesize;
      }

      this.tableItems = this.storeListData.slice(startsize, endsize);
    },
  },
};
</script>
<style scoped>
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 265px;
}
.head-table {
  background: white !important;
}
.table-border-out {
  border: 1px solid #ddd;
}

.Rectangle-841 {
  padding: 10px;
  border-radius: 5px;
  background-color: #005baa;
  text-align: center;
}

.counternumbers {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: inline;
  vertical-align: middle;
}

.Rectangle-845 {
  width: 46px;
  height: 36px;
  border-radius: 5px;
  background-color: #5779ae;
  float: right;
  color: #ffffff;
}

.mindiscount {
  width: 22px;
  height: 16px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding: 10px;
}
.merchpromlist .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchprof-sbtn:hover {
  color: #000000;
}
.merchpromolist--flasd-filter-from-to{
  justify-content: flex-end;
}
.countbox--seperator{
  font-size: 32px;
}
.mobile-endsiin-text{
  width: 100%;
  background: #005baa;
  padding: 3px;
  border-radius: 4px;
  margin-bottom: 12px;
  display: none;
}

/* Media queries */
@media screen and (max-width: 1425px) {
  .merchpromo-list--left{
    width: 100% !important;
  }
  .merchpromo-list--right{
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .merchpromolist--flasd-filter-from-to{
    justify-content: flex-start;
  }


}


@media screen and (max-width: 992px) {
    .merchprmolist--fdeal-coutbox{
      padding: 7px;
    }
    .merchprmolist--fdeal-coutbox .counternumbers{
      font-size: 12px;
    }
    
}

@media screen and (max-width: 768px) {
  .flashdeal-time--boxes-wpr{
    padding-left: 7px;
    padding-right: 7px;
  }
  .countbox--seperator{
      font-size: 24px;
    }

}

@media screen and (max-width: 530px) {
  .merchpromolist--flasd-filter-from-to-left{
    width: 100%;
    text-align: left;
  }
  .merchpromolist--flasd-filter-from-to-right{
    width: 100%;
    text-align: left;
  }



}

@media screen and (max-width: 475px) {
    .merchpromo-list--left{
      padding-left: 0px;
      padding-right: 0px;
    }
    .merchprmolist--fdeal-coutbox{
      padding: 3px;
    }
    .merchprmolist--fdeal-coutbox .counternumbers{
      font-size: 12px;
    }
}

@media screen and (max-width: 425px) {
    .desktop-endsiin-text{
      display: none;
    }
    .desktop-endsiin-seperator{
      display: none;
    }
    .mobile-endsiin-text{
      display: block;
    }

}
@media screen and (max-width: 315px) {
    .mobile-endsiin-text .counternumbers{
      font-size: 12px;
    }
}
</style>