import Domain from "../domain";
export default {
  methods: {
    getFlashDealList: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/flash/deal/view/details",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getFlashDealById: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/flash/deal/view/details/" + id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    addProductToFlashDetail: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/flash/deal/assing/product",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    removeProductFromFlashDetail: async function(id) {
      try {
        let response = await this.axios.delete(
          Domain.APP_DOMAIN + "merchant/flash/deal/remove/product/" + id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getCategoryListInPromotion: async function(array, discount, price, qty) {
      try {
        let endPoint = "";
        for (let x = 0; x < array.length; x++) {
          if (endPoint === "") {
            endPoint = "merchant/flash/deal/all/products?categories=" + array[x];
          } else {
            if (array[x] !== undefined) {
              endPoint = endPoint + "&categories=" + array[x];
            }
          }
        }
        let response = await this.axios.get(
          Domain.APP_DOMAIN + endPoint + "&min_discount=" + discount + "&min_price=" + price + "&min_qty=" + qty,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getLivePromotions: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/flash/deal/now",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  }
}